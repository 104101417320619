<script setup lang="ts">
import { inject, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { toast } from 'vue3-toastify'
import WaveSurfer from 'wavesurfer.js'
import { api } from '@src/api'
import { useAuthStore } from '@src/store/auth.ts'
import { useAvatarStore } from '@src/store/avatar.ts'
import { LocalEventBus } from '@src/event-bus.ts'
import Diary from '@src/components/Diary.vue'
import RemoveDiary from '@src/components/RemoveDiary.vue'
import Popup from '@src/components/Popup.vue'
import DayQuote from '@src/components/DayQuote.vue'
import DiaryEditor from '@src/components/DiaryEditor.vue'
import type { DiaryType, DiaryTypeFile } from '@src/api/diaries.ts'
import logoIconAvatar from '@src/assets/img/logo-icon.png'
import imgArchive from '@src/assets/img/img-archive.gif'
import FormText from '@src/components/form/FormText.vue'

let recorder: MediaRecorder
let recordDurationInterval: NodeJS.Timeout
let axiosController: AbortController
let blurHelper = true
let scrollY = 0

const authStore = useAuthStore()
const avatarStore = useAvatarStore()
const eventBus = inject(LocalEventBus)
const diariesList = ref<Record<number, typeof Diary>>({})
const isRecording = ref(false)
const recordDuration = ref(0)
const showEditor = ref(false)
const startDiary = ref<DiaryType>({
  id: 0,
  type: 'start',
  theme: '',
  text: '',
  reaction: null,
  userId: 0,
  files: [],
})
const startDiaryEnabled = ref(true)
const diaries = ref<DiaryType[]>([])
const diariesDraftCount = ref(0)
const diariesNonDraftCount = ref(0)
const diaryId = ref(0)
const diaryTheme = ref('')
const diaryText = ref('')
const diaryDraft = ref(false)
const diaryFiles = ref<DiaryTypeFile[]>([])
const isLoadingDiaries = ref(false)
const scrollToDiary = ref({
  id: 0,
  draft: false,
})
const deleteDiaryId = ref(0)
const deleteDiaryDraft = ref(false)
const showDeletePopup = ref(false)
const showArchive = ref(false)
const archivePercent = ref(0)
const currentAudioPlayer = ref<WaveSurfer | null>(null)

const currentPage = ref(1)
const totalPages = ref(1)

const showSearch = ref(false)
const searchText = ref('')
const searchResultsTotal = ref<number | null>(null)

const isPremium = ref(false)

const setItemRef = (el: any, id: number) => {
  diariesList.value[id] = el as typeof Diary
}

const loadDiaries = (page = 1) => {
  if (isLoadingDiaries.value) {
    return
  }

  isLoadingDiaries.value = true
  let limit = 10

  if (page === 1) {
    diaries.value = []
    diariesList.value = []
  }

  if (scrollToDiary.value.id !== 0 && !scrollToDiary.value.draft) {
    limit = currentPage.value * 10
  }

  api.diaries
    .list(searchText.value, page, limit)
    .then((data) => {
      currentPage.value = limit === 10 ? data.page : Math.round(limit / 10)
      totalPages.value = Math.ceil(data.total / 10)
      diariesDraftCount.value = data.draftCount ?? 0
      diariesNonDraftCount.value = data.nonDraftCount ?? 0
      searchResultsTotal.value = data.total

      data.diaries.forEach((item) => {
        item.type = item.isDraft ? 'draft' : ''
        diaries.value.push(item)
      })

      showEditor.value = false
      eventBus?.emit('editor', 'close')

      if (scrollToDiary.value.id !== 0) {
        nextTick(() => {
          let currentDiary: HTMLElement | null = null

          for (let diary of diaries.value) {
            if (
              ((diary.isDraft ?? false) === scrollToDiary.value.draft &&
                diary.id <= scrollToDiary.value.id) ||
              ((diary.isDraft ?? false) !== scrollToDiary.value.draft &&
                scrollToDiary.value.draft)
            ) {
              currentDiary = document.querySelector<HTMLElement>(
                `[data-diary="${diary.id}"]`,
              )
              break
            }
          }

          if (currentDiary) {
            const topElement = document.querySelector('.top') as HTMLElement
            window.scrollTo(0, currentDiary.offsetTop - topElement.offsetHeight)
          } else {
            window.scrollTo(0, document.body.scrollHeight)
          }

          scrollToDiary.value = {
            id: 0,
            draft: false,
          }
        })
      } else if (page === 1) {
        nextTick(() => {
          window.scrollTo(0, 0)
        })
      }
    })
    .finally(() => {
      isLoadingDiaries.value = false
    })
}

const newDiary = async () => {
  diaryId.value = 0
  diaryTheme.value = ''
  diaryText.value = ''
  diaryDraft.value = true
  diaryFiles.value = []

  await nextTick()
  showEditor.value = true
  eventBus?.emit('editor', 'show')
}

const newAudio = async () => {
  if (isRecording.value) {
    return
  }

  if (
    !window.AudioContext ||
    !window.MediaStreamAudioSourceNode ||
    !window.AudioWorkletNode
  ) {
    toast.error('Ваш браузер не поддерживает запись аудио')

    return
  }

  blurHelper = false

  navigator.mediaDevices
    .getUserMedia({
      audio: true,
    })
    .then(async (stream) => {
      if (blurHelper) {
        return [new Blob()]
      }

      recordDuration.value = 0
      isRecording.value = true

      recorder = new MediaRecorder(stream)
      let data: Blob[] = []

      recorder.ondataavailable = (event) => data.push(event.data)
      recorder.start()
      recordDurationInterval = setInterval(() => {
        recordDuration.value++
      }, 1000)

      let stopped = new Promise((resolve, reject) => {
        recorder.onstop = resolve
        recorder.onerror = reject
      })

      let recorded = new Promise<void>((resolve) => {
        setTimeout(
          () => {
            recorder.state == 'recording' && recorder.stop()
            resolve()
          },
          10 * 60 * 1000,
        )
      })

      return Promise.race([stopped, recorded]).then(() => data)
    })
    .then((recordedChunks) => {
      isRecording.value = false
      clearInterval(recordDurationInterval)

      if (recorder && recorder.stream) {
        recorder.stream.getTracks().forEach((track) => track.stop())
      }

      if (
        recordedChunks.length > 0 &&
        recordedChunks
          .map((item) => item.size)
          .reduce((sum, item) => sum + item) > 10000
      ) {
        api.files
          .uploadAudio(new Blob(recordedChunks, { type: 'audio/ogg' }))
          .then(({ data }) => {
            api.diaries
              .create({
                theme: 'Голосовая записка',
                text: 'Голосовая записка',
                isDraft: false,
                fileIds: [data],
              })
              .then((data) => {
                scrollToDiary.value = {
                  id: data.id,
                  draft: data.isDraft ?? false,
                }
                loadDiaries()
              })
          })
      }
    })
    .catch(() => {
      isRecording.value = false
      clearInterval(recordDurationInterval)

      if (recorder && recorder.stream) {
        recorder.stream.getTracks().forEach((track) => track.stop())
      }

      toast.error(
        'Ошибка доступа к микрофону. Возможно, доступ был заблокирован',
      )
    })
}

const audioStop = () => {
  blurHelper = true

  if (recorder && recorder.state === 'recording') {
    recorder.stop()
  }
}

const editDiary = async (id: number) => {
  diaryId.value = id
  diaryTheme.value = ''
  diaryText.value = ''
  diaryDraft.value = true
  diaryFiles.value = []

  diaries.value.forEach((value) => {
    if (value.id === diaryId.value) {
      diaryId.value = value.id
      diaryTheme.value = value.theme
      diaryText.value = value.text
      diaryDraft.value = value.isDraft ?? false
      diaryFiles.value = value.files ?? []
    }
  })

  scrollY = document.documentElement.scrollTop

  await nextTick()
  showEditor.value = true
  eventBus?.emit('editor', 'show')
}

const updateDiaries = (id: number, draft: boolean) => {
  scrollToDiary.value = {
    id,
    draft,
  }
  loadDiaries()
}

const updateOnePost = (
  id: number,
  theme: string,
  text: string,
  files?: DiaryTypeFile[],
) => {
  diaries.value.forEach((value, index) => {
    if (value.id === id) {
      showEditor.value = false
      eventBus?.emit('editor', 'close')

      nextTick(() => {
        diaries.value[index].theme = theme
        diaries.value[index].text = text
        diaries.value[index].files = files
      })
    }
  })
}

const handleClickRemove = async (id: number, draft: boolean) => {
  if (id === 0) {
    showEditor.value = false
    eventBus?.emit('editor', 'close')
  } else {
    deleteDiaryId.value = id
    deleteDiaryDraft.value = draft
    showDeletePopup.value = true
  }
}

const handlePopupDelete = () => {
  if (deleteDiaryId.value !== 0) {
    api.diaries.delete(deleteDiaryId.value).then(() => {
      scrollToDiary.value = {
        id: deleteDiaryId.value,
        draft: deleteDiaryDraft.value,
      }
      deleteDiaryId.value = 0
      showDeletePopup.value = false
      loadDiaries()
    })
  }
}

const handleAudioStart = (player: WaveSurfer) => {
  if (currentAudioPlayer.value !== null) {
    currentAudioPlayer.value.pause()
  }

  currentAudioPlayer.value = player
}

const handleAudioFinish = (id: number) => {
  currentAudioPlayer.value = null
  let isFind = false

  for (let diary of diaries.value) {
    if (!isFind && diary.id === id) {
      isFind = true
      continue
    }

    if (
      isFind &&
      diary.files &&
      diary.files.length > 0 &&
      diary.files[0].fileType === 'audio'
    ) {
      const currentDiary = document.querySelector<HTMLElement>(
        `[data-diary="${diary.id}"]`,
      )

      if (currentDiary) {
        const topElement = document.querySelector('.top') as HTMLElement
        window.scrollTo({
          top: currentDiary.offsetTop - topElement.offsetHeight,
          behavior: 'smooth',
        })
        diariesList.value[diary.id].play()
      }

      break
    }
  }
}

const downloadArchive = () => {
  axiosController = new AbortController()
  archivePercent.value = 0
  showArchive.value = true

  api.diaries
    .getArchive(axiosController.signal, (progressEvent) => {
      if (progressEvent.total) {
        archivePercent.value = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100,
        )
      } else {
        archivePercent.value = -1
      }
    })
    .then((data) => {
      showArchive.value = false
      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive.zip')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    })
    .catch(() => {
      showArchive.value = false
    })
}

const handleWindowScroll = () => {
  if (
    !showEditor.value &&
    !isLoadingDiaries.value &&
    currentPage.value < totalPages.value &&
    document.body.scrollHeight - 200 <
      document.documentElement.scrollTop + window.innerHeight
  ) {
    loadDiaries(currentPage.value + 1)
  }
}

const handleDocumentClick = (e: Event) => {
  const diaryParent = (e.target as HTMLElement).closest('.diary')

  if (
    diaryParent === null ||
    (diaryParent as HTMLElement).dataset.diary !== '0'
  ) {
    if (
      diariesList.value[0] &&
      document.querySelector('.diary[data-diary="0"].show-full')
    ) {
      const templateContent = document.querySelector(
        '.diary[data-diary="0"] .diary--content',
      ) as HTMLElement
      const scrollTo =
        document.documentElement.scrollTop - templateContent.offsetHeight + 85
      diariesList.value[0].hidePost()
      window.scrollTo(0, scrollTo)
    }
  }
}

const handleShowSearch = () => {
  searchText.value = ''
  searchResultsTotal.value = null
  showSearch.value = true
  diaries.value = []
  diariesList.value = []
}

const handleHideSearch = () => {
  searchText.value = ''
  searchResultsTotal.value = null
  showSearch.value = false
  loadDiaries()
}

authStore.$subscribe(
  (_, state) => {
    if (state.user) {
      isPremium.value = state.user.isPremium
    } else {
      isPremium.value = false
    }
  },
  {
    immediate: true,
  },
)

watch(
  () => searchText.value,
  (query) => {
    if (query.length >= 3) {
      loadDiaries()
    }
  },
  {
    immediate: true,
  },
)

eventBus?.on('editor', (action) => {
  switch (action) {
    case 'trash':
      handleClickRemove(diaryId.value, diaryDraft.value)
      break
    case 'close':
      showEditor.value = false
      nextTick(() => {
        if (scrollY !== 0) {
          window.scrollTo(0, scrollY)
          scrollY = 0
        }
      })
      break
  }
})

eventBus?.on('diarySearch', () => {
  if (showSearch.value) {
    handleHideSearch()
  }
})

onMounted(() => {
  loadDiaries()
  api.diaries.getDiaryTemplateSetting().then(({ isEnabled }) => {
    startDiaryEnabled.value = isEnabled
  })
  api.diaries.template().then(({ title, text }) => {
    startDiary.value.theme = title
    startDiary.value.text = text
  })

  window.addEventListener('scroll', handleWindowScroll)
  document.querySelector('.mic-btn')?.addEventListener('touchstart', (e) => {
    e.preventDefault()
  })
  document.addEventListener('mouseup', audioStop)
  document.addEventListener('blur', audioStop)
  document.addEventListener('click', handleDocumentClick)
})

onUnmounted(() => {
  eventBus?.emit('editor', 'close')

  window.removeEventListener('scroll', handleWindowScroll)
  document.removeEventListener('mouseup', audioStop)
  document.removeEventListener('blur', audioStop)
  document.removeEventListener('click', handleDocumentClick)
})
</script>

<template>
  <main v-show="!showEditor">
    <div
      v-if="!showSearch"
      class="header"
    >
      <div class="header--count">
        <div class="emotions-ledger"></div>
        <div class="header--count--text">
          <span class="public">
            {{ diariesNonDraftCount }}
            <template v-if="diariesDraftCount === 0">
              {{
                $pluralize(diariesNonDraftCount, [
                  'запись',
                  'записи',
                  'записей',
                ])
              }}
            </template>
          </span>
          <template v-if="diariesDraftCount > 0">
            /
            <span class="draft">
              {{ diariesDraftCount }}
              {{
                $pluralize(diariesDraftCount, [
                  'черновик',
                  'черновика',
                  'черновиков',
                ])
              }}
            </span>
          </template>
          <br />
          <span
            class="header--count--archive"
            @click="downloadArchive"
          >
            скачать архив
          </span>
        </div>
      </div>

      <RouterLink
        :to="{ name: 'tariff' }"
        class="header--tariff"
      >
        {{ isPremium ? 'премиум' : 'стартовый' }}

        <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.79 3.108a.679.679 0 0 0 0 .96L9.722 8 5.79 11.932a.679.679 0 1 0 .96.96l4.412-4.412a.679.679 0 0 0 0-.96L6.75 3.108a.679.679 0 0 0-.96 0Z" fill="#222"/></svg>
      </RouterLink>

      <div class="header--search">
        <div
          class="icon-link"
          @click="handleShowSearch"
        >
          <div class="icon-search"></div>
        </div>
      </div>
    </div>

    <div
      v-if="showSearch"
      class="search"
    >
      <img
        src="../../assets/img/img-search.gif"
        alt="Search"
      />

      <div style="position:relative">
        <FormText
          ref="search"
          v-model="searchText"
          id="search"
          type="text"
          class="search-input"
        />
        <div
          class="search-close icon-close"
          @click="handleHideSearch"
        ></div>
      </div>

      <div class="search--status">
        <template v-if="searchText.length < 3">
          <span class="icon-search"></span>
          <span>введите 3 символа</span>
        </template>
        <template v-else-if="searchResultsTotal === 0">
          <span class="icon-error"></span>
          <span>к сожалению, ничего не найдено<br />повторите поиск</span>
        </template>
        <template v-else-if="searchResultsTotal !== null">
          <span class="icon-like"></span>
          <span>
            найдено {{ searchResultsTotal }}
            {{
              $pluralize(searchResultsTotal, [
                'дневник',
                'дневника',
                'дневников',
              ])
            }}
          </span>
        </template>
      </div>
    </div>

    <div class="diaries--wrapper">
      <div class="diaries">
        <template v-if="!showSearch">
          <DayQuote />

          <div
            v-if="startDiary.text === ''"
            class="diary"
          >
            <img
              src="../../assets/img/loading.svg"
              class="loading"
              alt="Loading"
            />
          </div>
          <Diary
            v-else
            :ref="(el) => setItemRef(el, 0)"
            :item="startDiary"
            :author-avatar-data="logoIconAvatar"
            :is-enabled="startDiaryEnabled"
          />
        </template>

        <Diary
          v-for="diary in diaries"
          :key="diary.id"
          :ref="(el) => setItemRef(el, diary.id)"
          :item="diary"
          :author-avatar-data="avatarStore.avatarData"
          is-enabled
          can-edit
          @edit="editDiary"
          @remove="handleClickRemove"
          @audio-start="handleAudioStart"
          @audio-finish="handleAudioFinish"
        />

        <img
          v-if="isLoadingDiaries"
          class="diaries-loading"
          src="../../assets/img/loading.svg"
          alt="Loading"
        />
      </div>
    </div>
  </main>

  <div
    v-show="!showEditor && !showSearch"
    class="icon-add"
    :class="{ moved: isPremium }"
    @click="newDiary"
  ></div>

  <div
    v-if="isPremium"
    v-show="!showEditor && !showSearch"
    class="mic-btn"
    v-touch:press="newAudio"
    v-touch:release="audioStop"
  >
    <div
      class="icon-mic"
      :class="{ active: isRecording }"
    ></div>
    <div
      v-show="isRecording"
      class="waves"
    ></div>
  </div>

  <div
    v-if="isRecording"
    class="recording-panel"
  >
    <div class="recording-panel--time">
      {{
        Math.floor(recordDuration / 60) +
        ':' +
        Math.floor(recordDuration % 60)
          .toString()
          .padStart(2, '0')
      }}
    </div>
  </div>

  <DiaryEditor
    v-if="showEditor"
    :id="diaryId"
    :theme="diaryTheme"
    :text="diaryText"
    :draft="diaryDraft"
    :files="diaryFiles"
    :is-premium="isPremium"
    @update-diaries="updateDiaries"
    @update-one-post="updateOnePost"
  />

  <RemoveDiary
    v-model="showDeletePopup"
    @delete="handlePopupDelete"
    @close="showDeletePopup = false"
  />

  <Popup
    v-model="showArchive"
    :image="imgArchive"
    @after-close="() => axiosController.abort()"
  >
    <h3 class="h1 only-desktop">Загрузка!</h3>
    <h3 class="h2 only-mobile">Загрузка!</h3>

    <h5
      v-if="archivePercent > 0"
      class="h2"
    >
      {{ archivePercent }}%
    </h5>

    <div class="content">
      <div class="emotions-hourglass"></div>
      <template v-if="archivePercent === 0">
        Подготовка архива для скачивания ...
      </template>
      <template v-else> Архив подготовлен и скачивается ...</template>
    </div>

    <div
      class="btn primary"
      @click="showArchive = false"
    >
      Отмена
    </div>
  </Popup>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  &--count {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 12px;

    &--text {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      opacity: 0.8;

      @media screen and (min-width: $desktop) {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;
      }

      .draft {
        color: #e7125f;
      }
    }

    &--archive {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      color: #2f80ed;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      @media screen and (min-width: $desktop) {
        font-size: 15px;
        font-weight: 500;
        line-height: 1;
      }
    }
  }

  &--tariff {
    position: relative;
    padding: 9px 24px 9px 8px;
    display: block;
    color: #015BB0;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    background: #ffffff;
    border: 1px solid #E7F4F9;
    border-radius: 10px;
    cursor: pointer;

    svg {
      position: absolute;
      top: 7px;
      right: 3px;
    }
  }

  &--search {
    .icon-link {
      display: block;
      width: 24px;
      height: 24px;
      cursor: pointer;

      @media screen and (min-width: $desktop) {
        width: 28px;
        height: 28px;
      }

      div {
        font-size: 24px;

        @media screen and (min-width: $desktop) {
          font-size: 28px;
        }
      }

      img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 50%;
      }
    }
  }
}

.search {
  position: relative;

  > img {
    display: block;
    margin: 0 auto;
    width: 160px;

    @media screen and (min-width: $desktop) {
      width: 240px;
    }
  }

  &--status {
    padding-top: 20px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 9px;
    font-size: 15px;

    [class^='icon-'] {
      font-size: 24px;
    }
  }
}

.search-input {
  padding-top: 20px;
  width: 100%;

  :deep(input) {
    padding-right: 38px;
  }
}

.search-close {
  position: absolute;
  right: 10px;
  bottom: 13px;
  font-size: 24px;
  cursor: pointer;
}

.emotions-ledger {
  --size: 26;

  @media screen and (min-width: $desktop) {
    --size: 32;
  }
}

.diaries {
  margin-top: 10px;
  padding: 10px 0 62px;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    margin-top: 20px;
  }
}

.diaries-loading {
  margin: 20px auto 0;
  display: block;
  width: 50px;

  @media screen and (min-width: $desktop) {
    margin-top: 60px;
    width: 80px;
  }
}

.icon-add {
  position: fixed;
  right: 20px;
  bottom: 30px;
  padding: 18px;
  font-size: 24px;
  color: #0160b4;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 20px 40px 0 #cad9efcc;
  z-index: 9;
  cursor: pointer;

  @media screen and (min-width: $desktop) {
    left: calc(50% + 610px);
    right: auto;
    bottom: 60px;
  }

  &.moved {
    left: 20px;
    right: auto;

    @media screen and (min-width: $desktop) {
      left: calc(50% + 610px);
      bottom: 150px;
    }
  }
}

.mic-btn {
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 9;
  cursor: pointer;

  @media screen and (min-width: $desktop) {
    left: calc(50% + 610px);
    right: auto;
    bottom: 60px;
  }

  .waves {
    position: absolute;
    left: -52px;
    top: -52px;
    width: 132px;
    height: 142px;
    background: url(../../assets/img/img-waves.gif);
    background-size: 164px;
    z-index: -1;

    @media screen and (min-width: $desktop) {
      width: 142px;
    }
  }

  .icon-mic {
    padding: 18px;
    font-size: 24px;
    color: #0160b4;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 20px 40px 0 #cad9efcc;

    &.active {
      color: #ffffff;
      background: linear-gradient(0deg, #0da7f3, #0da7f3),
        linear-gradient(341.5deg, #10bdff -27.59%, #005ac4 234.81%),
        linear-gradient(219.16deg, #217cf2 9.47%, rgba(0, 176, 255, 0) 45.27%);
      box-shadow:
        0 20px 40px 0 #cad9efcc,
        3px 1px 15px 0 #ffffff80 inset,
        0 -3px 10px 0 #00000033 inset;
    }
  }
}

.recording-panel {
  position: fixed;
  right: 65px;
  bottom: 90px;
  padding: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 250px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 20px 40px 0 #cad9efcc;
  z-index: 11;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    left: calc(50% + 80px);
    right: auto;
    bottom: 60px;
    padding: 20px;
    width: 500px;
  }

  &--time {
    position: relative;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    opacity: 0.8;

    @media screen and (min-width: $desktop) {
      padding-left: 38px;
      font-size: 24px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 12px;
      height: 12px;
      background: #ff5858;
      border-radius: 50%;

      @media screen and (min-width: $desktop) {
        top: 3px;
        width: 18px;
        height: 18px;
      }
    }
  }

  &--save {
    position: relative;
    width: 18px;
    height: 18px;
    background: #015bb0;
    border: 1px solid #015bb0;
    border-radius: 9px;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      left: 4px;
      top: 4px;
      display: block;
      width: 10px;
      height: 10px;
      background: url('../../assets/img/i-check.svg') no-repeat center center;
    }
  }

  &--remove {
    color: #ff5858;
    cursor: pointer;
  }
}

.popup--content {
  h3 {
    margin-top: 0;
    margin-bottom: 40px;
    text-align: center;

    &.blue {
      color: #0da7f3;
    }

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }

  h5 {
    margin-top: 0;
    margin-bottom: 40px;
    text-align: center;
    color: #0da7f3;

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }

  .content {
    font-size: 14px;
    font-weight: 400;
    text-align: center;

    > div {
      position: relative;
      top: 3px;
      display: inline-block;
      --size: 20;
    }
  }

  .btn {
    margin-top: 40px;

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }
}
</style>
