<script setup lang="ts">
import { inject, nextTick, onMounted, onUnmounted, ref } from 'vue'
import { format } from 'date-fns'
import { api } from '@src/api'
import { useAuthStore } from '@src/store/auth.ts'
import { useAvatarStore } from '@src/store/avatar.ts'
import { LocalEventBus } from '@src/event-bus.ts'
import loadSecImage from '@src/loadSecImage.ts'
import Diary from '@src/components/Diary.vue'
import RemoveDiary from '@src/components/RemoveDiary.vue'
import DiaryEditor from '@src/components/DiaryEditor.vue'
import type { DiaryType, DiaryTypeFile } from '@src/api/diaries.ts'

type TCalDay = {
  date: Date
  time: number
  today: boolean
  hidden: boolean
  count: number
  uuid?: string
}

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

const showDots = [1, 1, 2, 3]

const authStore = useAuthStore()
const avatarStore = useAvatarStore()
const eventBus = inject(LocalEventBus)
const today = ref(new Date())
const currentDay = ref(new Date())
const calendarMonth = ref(0)
const calendarYear = ref(0)
const calDays = ref<TCalDay[]>([])
const diariesEl = ref<HTMLElement | null>(null)
const diariesList = ref<Record<number, typeof Diary>>({})
const showEditor = ref(false)
const diaries = ref<DiaryType[]>([])
const diaryId = ref(0)
const diaryTheme = ref('')
const diaryText = ref('')
const diaryDraft = ref(false)
const diaryFiles = ref<DiaryTypeFile[]>([])
const isLoading = ref(false)
const isLoadingDiaries = ref(false)
const scrollToDiary = ref({
  id: 0,
  draft: false,
})
const deleteDiaryId = ref(0)
const deleteDiaryDraft = ref(false)
const showDeletePopup = ref(false)

const currentPage = ref(1)
const totalPages = ref(1)

const isPremium = ref(false)

const loadCalendar = (month: number, year: number) => {
  if (isLoading.value) {
    return
  }

  isLoading.value = true
  const todayTime = today.value.getTime()
  calDays.value = []

  const firstDay = new Date(year, month, 1)
  const firstCalDay = new Date(
    year,
    month,
    firstDay.getDay() === 0 ? -5 : -firstDay.getDay() + 2,
  )
  const firstDayTime = firstDay.getTime()

  const lastDay = new Date(year, month + 1, 0)
  const lastCalDay = new Date(
    year,
    month + 1,
    lastDay.getDay() === 0 ? 0 : 7 - lastDay.getDay(),
  )
  const lastDayTime = lastDay.getTime()

  calendarMonth.value = firstDay.getMonth()
  calendarYear.value = firstDay.getFullYear()

  api.diaries
    .calendar(
      format(firstCalDay, 'yyyy-MM-dd'),
      format(lastCalDay, 'yyyy-MM-dd'),
    )
    .then((data) => {
      for (
        let dt = firstCalDay;
        dt <= lastCalDay;
        dt.setDate(dt.getDate() + 1)
      ) {
        const date = new Date(dt)
        const time = date.getTime()
        calDays.value.push({
          date,
          time,
          hidden: time < firstDayTime || time > lastDayTime,
          today: time === todayTime,
          ...(data[format(date, 'yyyy-MM-dd')] ?? { count: 0 }),
        })
      }

      isLoading.value = false
    })
}

const chooseItem = (item: TCalDay) => {
  if (!item.today && item.count === 0) {
    return
  }

  chooseDate(item.date)
}

const chooseDate = (date: Date) => {
  if (isLoading.value || isLoadingDiaries.value) {
    return
  }

  let newCalendar =
    calendarMonth.value !== date.getMonth() ||
    calendarYear.value !== date.getFullYear()
  currentDay.value = new Date(date)

  if (newCalendar) {
    loadCalendar(currentDay.value.getMonth(), currentDay.value.getFullYear())
  }

  loadDiaries()
}

const setItemRef = (el: any, id: number) => {
  diariesList.value[id] = (el as typeof Diary)
}

const loadDiaries = (page = 1) => {
  if (isLoadingDiaries.value) {
    return
  }

  isLoadingDiaries.value = true
  let limit = 10

  if (page === 1) {
    diaries.value = []
    diariesList.value = []
  }

  if (scrollToDiary.value.id !== 0 && !scrollToDiary.value.draft) {
    limit = currentPage.value * 10
  }

  api.diaries
    .listByDate(format(currentDay.value, 'yyyy-MM-dd'), page, limit)
    .then((data) => {
      currentPage.value = limit === 10 ? data.page : Math.round(limit / 10)
      totalPages.value = Math.ceil(data.total / 10)

      data.diaries.forEach((item) => {
        item.type = item.isDraft ? 'draft' : ''
        diaries.value.push(item)
      })

      showEditor.value = false
      eventBus?.emit('editor', 'close')

      if (scrollToDiary.value.id !== 0) {
        nextTick(() => {
          let currentDiary: HTMLElement | null = null

          for (let diary of diaries.value) {
            if (
              ((diary.isDraft ?? false) === scrollToDiary.value.draft &&
               diary.id <= scrollToDiary.value.id) ||
              ((diary.isDraft ?? false) !== scrollToDiary.value.draft && scrollToDiary.value.draft)
            ) {
              currentDiary = document.querySelector<HTMLElement>(`[data-diary="${diary.id}"]`)
              break
            }
          }

          if (currentDiary) {
            diariesEl.value!.scrollTo(0, currentDiary.offsetTop - diariesEl.value!.offsetTop)
          } else {
            diariesEl.value!.scrollTo(0, diariesEl.value!.scrollHeight)
          }

          scrollToDiary.value = {
            id: 0,
            draft: false,
          }
        })
      } else if (page === 1) {
        nextTick(() => {
          diariesEl.value?.scrollTo(0, 0)
        })
      }
    })
    .finally(() => {
      isLoadingDiaries.value = false
    })
}

const editDiary = async (id: number) => {
  diaryId.value = id
  diaryTheme.value = ''
  diaryText.value = ''
  diaryDraft.value = true
  diaryFiles.value = []

  diaries.value.forEach((value) => {
    if (value.id === diaryId.value) {
      diaryId.value = value.id
      diaryTheme.value = value.theme
      diaryText.value = value.text
      diaryDraft.value = value.isDraft ?? false
      diaryFiles.value = value.files ?? []
    }
  })

  await nextTick()
  showEditor.value = true
  eventBus?.emit('editor', 'show')
}

const updateDiaries = (id: number, draft: boolean) => {
  scrollToDiary.value = {
    id,
    draft,
  }
  loadDiaries()
}

const updateOnePost = (
  id: number,
  theme: string,
  text: string,
  files?: DiaryTypeFile[],
) => {
  diaries.value.forEach((value, index) => {
    if (value.id === id) {
      showEditor.value = false
      eventBus?.emit('editor', 'close')

      nextTick(() => {
        diaries.value[index].theme = theme
        diaries.value[index].text = text
        diaries.value[index].files = files
      })
    }
  })
}

const handleClickRemove = async (id: number, draft: boolean) => {
  if (id === 0) {
    showEditor.value = false
    eventBus?.emit('editor', 'close')
  } else {
    deleteDiaryId.value = id
    deleteDiaryDraft.value = draft
    showDeletePopup.value = true
  }
}

const handlePopupDelete = () => {
  if (deleteDiaryId.value !== 0) {
    api.diaries.delete(deleteDiaryId.value).then(() => {
      scrollToDiary.value = {
        id: deleteDiaryId.value,
        draft: deleteDiaryDraft.value,
      }
      deleteDiaryId.value = 0
      showDeletePopup.value = false
      loadDiaries()
    })
  }
}

const handleAudioFinish = (id: number) => {
  let isFind = false

  for (let diary of diaries.value) {
    if (!isFind && diary.id === id) {
      isFind = true
      continue
    }

    if (isFind && diary.files && diary.files.length > 0 && diary.files[0].fileType === 'audio') {
      const currentDiary = document.querySelector<HTMLElement>(`[data-diary="${diary.id}"]`)

      if (currentDiary) {
        diariesEl.value!.scrollTo({
          top: currentDiary.offsetTop - diariesEl.value!.offsetTop,
          behavior: 'smooth',
        })
        diariesList.value[diary.id].play()
      }

      break
    }
  }
}

authStore.$subscribe(
  (_, state) => {
    if (state.user) {
      isPremium.value = state.user.isPremium
    } else {
      isPremium.value = false
    }
  },
  {
    immediate: true,
  },
)

eventBus?.on('editor', (action) => {
  switch (action) {
    case 'trash':
      handleClickRemove(diaryId.value, diaryDraft.value)
      break
    case 'close':
      showEditor.value = false
      break
  }
})

eventBus?.on('calendar', (action) => {
  if (action === 'today') {
    chooseDate(today.value)
  }
})

onMounted(() => {
  today.value.setHours(0, 0, 0, 0)
  currentDay.value = new Date(today.value)

  loadCalendar(currentDay.value.getMonth(), currentDay.value.getFullYear())
  loadDiaries()

  diariesEl.value?.addEventListener('scroll', () => {
    if (
      !isLoadingDiaries.value &&
      currentPage.value < totalPages.value &&
      diariesEl.value!.scrollHeight - 200 <
        diariesEl.value!.scrollTop + diariesEl.value!.offsetHeight
    ) {
      loadDiaries(currentPage.value + 1)
    }
  })
})

onUnmounted(() => {
  eventBus?.emit('editor', 'close')
})
</script>

<template>
  <main v-show="!showEditor">
    <div class="calendar">
      <div class="calendar--top">
        <div
          class="calendar--left"
          @click="loadCalendar(calendarMonth - 1, calendarYear)"
        ></div>

        <div class="calendar--name">
          <div class="calendar--month h3">
            {{ months[calendarMonth] }}
          </div>
          <div class="calendar--year">{{ calendarYear }}</div>
        </div>

        <div
          class="calendar--right"
          @click="loadCalendar(calendarMonth + 1, calendarYear)"
        ></div>
      </div>

      <div class="calendar--week">
        <div
          v-for="day in ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']"
          class="calendar--week--item"
        >
          {{ day }}
        </div>
      </div>

      <img
        v-if="isLoading"
        class="calendar--loading"
        src="../../assets/img/loading.svg"
        alt="Loading"
      />

      <div
        v-else
        class="calendar--days"
      >
        <div
          v-for="item in calDays"
          :key="item.time"
          class="calendar--days--item"
          :class="{
            exist: item.count > 0,
            'has-image': !item.today && item.uuid,
            today: item.today,
            current: item.date.getTime() === currentDay.getTime(),
            hidden: item.hidden,
          }"
          @click="chooseItem(item)"
        >
          <img
            v-if="!item.today && item.uuid"
            :src="loadSecImage(item.uuid, 'thumbnail')"
            alt=""
          />

          {{ item.date.getDate() }}

          <div
            v-if="!item.today && item.count > 1"
            class="calendar--dots"
          >
            <template v-for="showDot in showDots">
              <div
                v-if="item.count > showDot"
                class="calendar--dots--dot"
              ></div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="diaries--wrapper">
      <div
        ref="diariesEl"
        class="diaries"
      >
        <Diary
          v-for="diary in diaries"
          :key="diary.id"
          :ref="(el) => setItemRef(el, diary.id)"
          :item="diary"
          :author-avatar-data="avatarStore.avatarData"
          is-enabled
          can-edit
          @edit="editDiary"
          @remove="handleClickRemove"
          @audio-finish="handleAudioFinish"
        />

        <img
          v-if="isLoadingDiaries"
          class="diaries-loading"
          src="../../assets/img/loading.svg"
          alt="Loading"
        />

        <div v-else-if="diaries.length === 0">
          Сегодня записей не было
        </div>
      </div>
    </div>
  </main>

  <DiaryEditor
    v-if="showEditor"
    :id="diaryId"
    :theme="diaryTheme"
    :text="diaryText"
    :draft="diaryDraft"
    :files="diaryFiles"
    :is-premium="isPremium"
    @update-diaries="updateDiaries"
    @update-one-post="updateOnePost"
  />

  <RemoveDiary
    v-model="showDeletePopup"
    @delete="handlePopupDelete"
    @close="showDeletePopup = false"
  />
</template>

<style scoped lang="scss">
main {
  padding-top: 30px;

  @media screen and (min-width: $desktop) {
    margin: 0 auto;
    padding-top: 120px;
    display: flex;
    flex-flow: row nowrap;
    align-items: start;
    width: 1200px;
  }
}

.calendar {
  margin: 0 -5.5px;
  height: 500px;
  transition: height 0.5s ease-out;

  @media screen and (min-width: $desktop) {
    flex: 0 0 540px;
    margin: 0 30px;
    height: auto;
  }

  &--top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    user-select: none;
  }

  &--left {
    margin-left: 14px;
    width: 18px;
    height: 18px;
    background: url(../../assets/img/i-arrow-left.svg) right top no-repeat;
    cursor: pointer;

    @media screen and (min-width: $desktop) {
      margin-left: 28px;
      width: 27px;
      height: 27px;
      background-size: 27px;
    }
  }

  &--right {
    margin-right: 14px;
    width: 18px;
    height: 18px;
    background: url(../../assets/img/i-arrow-right.svg) left top no-repeat;
    cursor: pointer;

    @media screen and (min-width: $desktop) {
      margin-right: 28px;
      width: 27px;
      height: 27px;
      background-size: 27px;
    }
  }

  &--name {
    text-align: center;
  }

  &--month {
    margin-bottom: 6px;
    color: #015bb0;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;

    @media screen and (min-width: $desktop) {
      margin-bottom: 8px;
      font-size: 36px;
      line-height: 1.2;
    }
  }

  &--year {
    color: #a1acc1;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    opacity: 0.8;

    @media screen and (min-width: $desktop) {
      font-size: 18px;
      line-height: 1;
    }
  }

  &--week {
    margin: 28px 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @media screen and (min-width: $desktop) {
      margin: 42px 0;
    }

    &--item {
      width: 46px;
      color: #a1acc1;
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      opacity: 0.8;

      @media screen and (min-width: $desktop) {
        width: 66px;
        font-size: 18px;
        line-height: 1;
      }
    }
  }

  &--loading {
    margin: 20px auto 0;
    display: block;
    width: 50px;

    @media screen and (min-width: $desktop) {
      margin-top: 60px;
      width: 80px;
    }
  }

  &--days {
    display: grid;
    grid-template-columns: repeat(7, 46px);
    grid-template-rows: repeat(6, 64px);
    justify-content: space-between;

    @media screen and (min-width: $desktop) {
      grid-template-columns: repeat(7, 66px);
      grid-template-rows: repeat(6, 86px);
    }

    &--item {
      position: relative;
      margin: 3px 3px 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Noto Serif", serif;
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      border-radius: 15px;
      user-select: none;

      @media screen and (min-width: $desktop) {
        margin: 5px 5px 25px;
        font-size: 24px;
        border-radius: 22.5px;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
        z-index: -1;

        @media screen and (min-width: $desktop) {
          border-radius: 22.5px;
        }
      }

      &.exist {
        color: #ffffff;
        font-weight: 900;
        background: linear-gradient(341.5deg, #10bdff -27.59%, #005ac4 234.81%);
        box-shadow: -12px 18px 20px 0px #4646460f;
        cursor: pointer;

        @media screen and (min-width: $desktop) {
          box-shadow: -18px 27px 30px 0px #4646460f;
        }
      }

      &.has-image {
        background: linear-gradient(
          0deg,
          rgba(0, 3, 81, 0.1),
          rgba(0, 3, 81, 0.1)
        );
      }

      &.today {
        color: #00b3ff;
        font-weight: 500;
        background: none;
        box-shadow: none;
        cursor: pointer;

        &:after {
          content: "";
          position: absolute;
          bottom: 7px;
          margin: 0 auto;
          width: 26px;
          height: 2px;
          background: #00b3ff;

          @media screen and (min-width: $desktop) {
            width: 39px;
            height: 3px;
          }
        }
      }

      &.current {
        margin: 0 0 20px;
        color: #7dffc9;
        font-weight: 900;
        background: #1995cd;
        border: 3px solid #caf6ffc9;
        box-shadow: 0 9px 8px 0 #00057766 inset;
        cursor: default;

        @media screen and (min-width: $desktop) {
          border-width: 5px;
        }

        &:after {
          background: #7dffc9;
        }
      }

      &.hidden {
        opacity: 0.2;
      }
    }
  }

  &--dots {
    position: absolute;
    bottom: -9px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 4px;

    @media screen and (min-width: $desktop) {
      bottom: -15px;
      gap: 6px;
    }

    &--dot {
      width: 3px;
      height: 3px;
      background: #0da6f1;
      border-radius: 50%;

      @media screen and (min-width: $desktop) {
        width: 5px;
        height: 5px;
      }
    }
  }
}

.diaries {
  padding: 10px 0 62px;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    margin: 25px 0 0;
    padding: 10px 15px 62px;
    width: 560px;
    height: calc(100dvh - 265px);
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  &--wrapper {
    position: relative;

    @media screen and (min-width: $desktop) {
      padding: 0;
      margin-left: 40px;
    }
  }
}

@media screen and (min-width: $desktop) {
  .diary {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    background: #ffffff;
    border: 1px solid #4cdfa2;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 #cad9ef66;

    + .diary {
      margin-top: 20px;
    }

    :deep(.diary--title) {
      font-size: 22px;
      line-height: 1.2;
    }

    :deep(.diary--content) {
      font-size: 15px;
      line-height: 1.3;
    }

    :deep(.diary--images) {
      padding: 10px 0 15px;
      gap: 20px;
    }

    :deep(.diary--images > div.video:after) {
      top: -4px;
      right: -4px;
      width: 30px;
      height: 30px;
      background-size: 20px;
      box-shadow: 0 13.91px 27.83px 0 #cad9efcc;
    }

    :deep(.diary--images img) {
      width: 90px;
      height: 90px;
      border-radius: 20px;
    }
  }
}

.diaries-loading {
  margin: 20px auto 0;
  display: block;
  width: 50px;

  @media screen and (min-width: $desktop) {
    margin-top: 60px;
    width: 80px;
  }
}
</style>
