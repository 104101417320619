<script setup lang="ts">
import { ref } from 'vue'
import FormCheckbox from '@src/components/form/FormCheckbox.vue'

defineProps<{
  isPremium: boolean
}>()

const showBase = ref(false)
const choosePremium = ref(true)
</script>

<template>
  <div class="plans-wrapper">
    <div
      :class="{ choose: showBase, last: isPremium }"
      class="plan plan--start"
    >
      <div class="plan--main">
        <div class="plan--main--top">
          <div class="plan--icon emotions-partying-face"></div>
          <div class="plan--info">
            <div class="plan--name">
              Стартовый
              <span v-if="!isPremium">(текущий)</span>
            </div>
            <div class="plan--description">Бесплатно навсегда</div>
          </div>
        </div>

        <div class="plan--main--bottom">
          <div class="plan--price">Бесплатно</div>
        </div>
      </div>

      <div
        class="plan--show"
        @click.stop="showBase = !showBase"
      >
        <p>{{ showBase ? 'скрыть' : 'подробнее' }}</p>
        <div
          v-show="!showBase"
          class="icon-chevron-down"
        ></div>
        <div
          v-show="showBase"
          class="icon-chevron-up"
        ></div>
      </div>

      <div class="plan--additional">
        <div class="plan--additional--features">
          <div class="plan--additional--features--item">
            <img
              src="../assets/img/img-plan-text.svg"
              alt="безлимит на текстовые записи"
            />
            <p>безлимит на текстовые записи</p>
          </div>
          <div class="plan--additional--features--item">
            <img
              src="../assets/img/img-plan-photo.svg"
              alt="безлимит размещения фото"
            />
            <p>безлимит размещения фото</p>
          </div>
          <div class="plan--additional--features--item">
            <img
              src="../assets/img/img-plan-download.svg"
              alt="можно скачать дневник"
            />
            <p>можно скачать дневник</p>
          </div>
          <div class="plan--additional--features--item">
            <img
              src="../assets/img/img-plan-write.svg"
              alt="шаблоны писем для вдохновения"
            />
            <p>шаблоны писем для вдохновения</p>
          </div>
        </div>

        <div class="plan--additional--notes">
          + <strong>бесплатная</strong> мотивационная поддержка
          <span class="emotions-incoming-envelope"></span>
        </div>
      </div>
    </div>

    <div class="plan--line">
      <div
        v-if="isPremium"
        style="font-weight:600"
      >
        Поздравляем! У Вас<br/>Премиум План!<br />
        <span style="font-weight:600;color:#015fb3">БЕСПЛАТНО НАВСЕГДА</span>
      </div>
      <div v-else>
        Повысьте план<br />
        <span style="font-weight:600;color:#ef0000">ВО ВРЕМЯ АКЦИИ БЕСПЛАТНО</span>
      </div>

      <img
        src="../assets/img/img-airplane.gif"
        alt=""
      />
    </div>

    <div
      :class="{ first: isPremium }"
      class="plan plan--premium choose"
    >
      <div class="plan--main">
        <div class="plan--main--top">
          <div class="plan--icon emotions-premium"></div>
          <div class="plan--info">
            <div class="plan--name">
              Премиум
              <span v-if="isPremium">(текущий)</span>
            </div>
            <div class="plan--description">Бесплатно навсегда</div>
          </div>
          <div
            v-if="!isPremium"
            class="plan--input"
          >
            <FormCheckbox
              v-model="choosePremium"
              id="choosePremium"
              @click.prevent="choosePremium = true"
            />
          </div>
        </div>

        <div class="plan--main--bottom">
          <div class="plan--price">Бесплатно</div>

          <div class="plan--price--old">₽ 499.00</div>
        </div>
      </div>

      <div class="plan--additional">
        <div class="plan--additional--notes">
          Все бонусы стартового плана <strong>+апгрейд</strong>:
        </div>

        <div class="plan--additional--features">
          <div class="plan--additional--features--item">
            <img
              src="../assets/img/img-plan-video.svg"
              alt="видеофайлы"
            />
            <p>видео&shy;файлы</p>
          </div>
          <div class="plan--additional--features--item">
            <img
              src="../assets/img/img-plan-mic.svg"
              alt="голосовые записки"
            />
            <p>голосовые записки</p>
          </div>
          <div class="plan--additional--features--item">
            <img
              src="../assets/img/img-plan-crown.svg"
              alt="премиум аватарка"
            />
            <p>премиум аватарка</p>
          </div>
          <div class="plan--additional--features--item">
            <img
              src="../assets/img/img-plan-gift.svg"
              alt="новогодняя лотерея"
            />
            <p>новогодняя лотерея</p>
          </div>
        </div>

        <div class="plan--additional--bottom"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.plans-wrapper {
  margin: 16px 0 26px;
  display: flex;
  flex-flow: column nowrap;
  gap: 19px;

  @media screen and (min-width: $desktop) {
    flex-flow: row wrap;
    align-items: start;
    gap: 45px;
  }
}

.plan {
  margin: 0 auto;
  padding: 18px 14px;
  width: 328px;
  border-radius: 30px;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    margin: 0;
  }

  &.plan--premium.choose {
    background-color: #ffffff;
    box-shadow: 0 20px 40px 0 #cad9efcc;
  }

  &.plan--start {
    @media screen and (min-width: $desktop) {
      height: 303px;
    }
  }

  &.first {
    order: -1;
  }

  &.last {
    order: 2;
  }

  &--main {
    padding: 16px 14px;
    background-color: #ffffff;
    border: 1px solid #eff6ff;
    border-radius: 10px;

    &--top {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      gap: 10px;
    }

    &--bottom {
      position: relative;
      margin-top: 16px;
      display: flex;
      flex-flow: row-reverse nowrap;
      align-items: center;
      justify-content: space-between;
    }
  }

  &--icon {
    --size: 40;
  }

  &--info {
    margin: 2px 0;
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  &--name {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
  }

  &--description {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: #146fda;
  }

  &--input {
    align-self: center;

    :deep(label) {
      margin: 0;
    }
  }

  &--price {
    color: #27ae60;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;

    &--old {
      color: #222222;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-decoration: line-through;
    }
  }

  &--additional {
    display: none;

    .choose & {
      display: block;
    }

    &--notes {
      margin-top: 14px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.3;

      [class*='emotions-'] {
        --size: 24;
        position: relative;
        top: 7px;
        display: inline-block;
      }
    }

    &--features {
      margin-top: 14px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      &--item {
        flex: 0 0 60px;
        width: 60px;
        font-size: 9px;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;

        img {
          margin-bottom: 10px;
        }
      }
    }
  }

  &--show {
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 5px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    opacity: 0.8;
    cursor: pointer;
  }

  &--line {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.3;

    @media screen and (min-width: $desktop) {
      order: -2;
      margin: 0 auto -30px;
      width: 330px;
    }

    img {
      width: 100px;
    }
  }
}

.float-free {
  position: absolute;
  top: -22px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;

  > div {
    --size: 24;
    position: relative;
    top: 7px;
    margin-right: 10px;
    display: inline-block;
  }
}
</style>
